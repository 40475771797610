import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Bet from './pages/Bet';
import Proof from './pages/Proof';
import ProofDetails from './pages/ProofDetails';
import Result from './pages/Result';
import Prize from './pages/Prize';
import Ticket from './pages/Ticket';
import TicketDetails from './pages/TicketDetails';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/bet" element={<Bet />} />
        <Route path="/proof" element={<Proof />} />
        <Route path="/proof-details" element={<ProofDetails />} />
        <Route path="/result" element={<Result />} />
        <Route path="/prize" element={<Prize />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticket-details" element={<TicketDetails />} />
	
<Route path="/auth/twitter" element={() => {
          console.log('React is handling /auth/twitter');
          return <div>React is handling Twitter OAuth</div>;
        }} />
           
     </Routes>
    </Router>
  );
};

export default App;
