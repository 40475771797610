import React from 'react';
import styled from 'styled-components';

// Styled Components for the button and image
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 10px;
`;

const ButtonImage = styled.img`
  width: 30px;
  height: auto;
  margin-bottom: 10px;
`;

const ButtonText = styled.p`
  font-size: 16px;
  color: #fff;
  font-family: 'Press Start 2P', cursive;
`;

const ButtonWithImage = ({ imageSrc, text, onClick }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <ButtonImage src={imageSrc} alt={text} />
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  );
};

export default ButtonWithImage;
