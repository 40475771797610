import React, { useState } from 'react';
import styled from 'styled-components';
import SquareButtonWithImage from '../SquareButtonWithImage';  // Import the button component

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 20px;
  position: fixed;
  top: 0;
  background-color: #45303f;  /* Adjust background color */
  z-index: 1000;  /* Ensure it's on top */
`;

const Header = ({ walletAddress, connectWallet }) => {
  // State to track the pushed status of each button
  const [isPrizePushed, setIsPrizePushed] = useState(false);
  const [isTicketPushed, setIsTicketPushed] = useState(false);
  const [isProofPushed, setIsProofPushed] = useState(false);

  return (
    <HeaderWrapper>
      {/* Prize Button */}
      <SquareButtonWithImage
        imageSrc={isPrizePushed ? "/images/Prize_Pushed.png" : "/images/Prize_Unpushed.png"}
        text="Prize: 2.3 ETH"
        onClick={() => setIsPrizePushed(!isPrizePushed)}  // Toggle the pushed state
      />

      {/* Ticket Button */}
      <SquareButtonWithImage
        imageSrc={isTicketPushed ? "/images/ticket_pushed.png" : "/images/ticket_unpushed.png"}
        text="Ticket: 0.12 ETH"
        onClick={() => setIsTicketPushed(!isTicketPushed)}  // Toggle the pushed state
      />

      {/* Connect Wallet Button */}
      <SquareButtonWithImage
        imageSrc={walletAddress ? "/images/Connect_Wallet_Pushed.png" : "/images/Connect_Wallet_Unpushed.png"}
        text={walletAddress ? `Wallet: ${walletAddress?.slice(0, 6)}...${walletAddress?.slice(-4)}` : 'Connect Wallet'}
        onClick={walletAddress ? null : connectWallet}  // Disable onClick if wallet is connected
        disabled={!!walletAddress}  // Disable the button if wallet is connected
      />

      {/* Proof Button */}
      <SquareButtonWithImage
        imageSrc={isProofPushed ? "/images/proof_pushed.png" : "/images/proof.png"}
        text="Proof"
        onClick={() => setIsProofPushed(!isProofPushed)}  // Toggle the pushed state
      />
    </HeaderWrapper>
  );
};

export default Header;
