import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SquareButtonWithImage from '../components/SquareButtonWithImage';

// Styled components for the layout
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #45303f;
  color: white;
  font-family: 'Press Start 2P', cursive;
`;

// Add a styled component for the larger text
const LargerText = styled.p`
  font-size: 24px;  /* Adjust the size as needed */
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;  /* Optional: Adjust color if needed */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

const Section = styled.div`
  margin: 20px;
`;

const StoryText = styled.p`
  max-width: 600px;
  text-align: center;
  font-size: 14px;
  margin: 10px;
`;

const WarriorImage = styled.img`
  margin-top: 20px;
  width: 30px;
`;

const SelectionImage = styled.img`
  margin-top: 20px;
  width: 150px;
`;

const FinalChoiceImage = styled.img`
  margin-top: 20px;
  width: 150px;
`;

const FinalChoiceText = styled.p`
  font-size: 14px;
  margin-top: 20px;
`;

const Bet = () => {
  const [storyData, setStoryData] = useState(null);  // State for story data
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isBetMade, setIsBetMade] = useState(false);

  // Fetch the story.json from the public folder on component mount
  useEffect(() => {
    const fetchStoryData = async () => {
      try {
        const response = await fetch('/images/generated_images/AIcontentGen/story.json');
        const data = await response.json();
        setStoryData(data);
      } catch (error) {
        console.error('Error fetching story data:', error);
      }
    };

    fetchStoryData();
  }, []);

  const handleOptionSelect = (questionKey, optionIndex) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [questionKey]: optionIndex,
    }));
  };

  const handleBet = () => {
    setIsBetMade(true);
  };

  if (!storyData) {
    return <p>Loading story...</p>;
  }

  return (
    <PageWrapper>
      <Header>
        <SquareButtonWithImage imageSrc="/images/Prize_Unpushed.png" text="prize: 2.3ETH" />
        <SquareButtonWithImage imageSrc="/images/ticket_unpushed.png" text="ticket: 0.12" />
        <SquareButtonWithImage imageSrc="/images/Connect_Wallet_Unpushed.png" text="connect wallet" />
        <SquareButtonWithImage imageSrc="/images/proof_bordered.png" text="proof" />
      </Header>

      <Section>
        <img src="/images/Chapter-1.png" alt="Chapter 1" />
        <StoryText>{storyData.story}</StoryText>
          
        {/* Add the larger text after the story paragraph */}
        <LargerText>What do you look like at the mysterious costume ball?</LargerText>

      </Section>

      {Object.keys(storyData.questions).map((questionKey) => (
        <Section key={questionKey}>
          <h3>{storyData.questions[questionKey].question}</h3>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {storyData.questions[questionKey].options.map((option, index) => (
              <SquareButtonWithImage
                key={index}
                imageSrc={`/images/generated_images/AIcontentGen/option_pictures/${option.image}`}
                text={`Option ${index + 1}`}
                onClick={() => handleOptionSelect(questionKey, index)}
              />
            ))}
          </div>
        </Section>
      ))}

      <FinalChoiceText>So warrior, your final choice is...</FinalChoiceText>
      <FinalChoiceImage src="/images/costume_result.png" alt="Warrior" />
      <SelectionImage src="/images/selection_sword.png" alt="Sword with Selection" />
      <WarriorImage src="/images/coin_ticket_calculation.png" alt="Warrior" />
      <WarriorImage src="/images/warrior.png" alt="Warrior" />

      <SquareButtonWithImage
        imageSrc="/images/bet_unpushed.png"
        text="bet warrior?"
        onClick={handleBet}
      />
      
      <FinalChoiceText>Joined warriors: 3233</FinalChoiceText>
    </PageWrapper>
  );
};

export default Bet;
