import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  
import styled from 'styled-components';
import SquareButtonWithImage from '../components/SquareButtonWithImage';  
import Header from '../components/Header/Header';  // Import Header component

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #45303f;
  color: white;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
`;

const HomeImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`;

const TitleImage = styled.img`
  width: 250px;
  position: relative;
  top: -30px;
`;

const Text = styled.p`
  font-size: 18px;
  margin: 10px 0;
`;

const Home = () => {
  const location = useLocation();  // Access state passed from LandingPage
  const navigate = useNavigate();  

  const [walletAddress, setWalletAddress] = useState(null);
  const [isPrizePushed, setIsPrizePushed] = useState(false);
  const [isTicketPushed, setIsTicketPushed] = useState(false);

  // Retrieve walletAddress from location state, with a fallback mechanism
  useEffect(() => {
    if (location.state?.walletAddress) {
      setWalletAddress(location.state.walletAddress);
    } else {
      navigate('/');  // Optionally, redirect to landing if walletAddress is missing
    }
  }, [location.state, navigate]);

  return (
    <PageWrapper>
      {/* Use Header Component and pass walletAddress */}
      <Header 
        walletAddress={walletAddress}  // No need for separate isWalletConnected
        isPrizePushed={isPrizePushed}
        setIsPrizePushed={setIsPrizePushed}
        setIsTicketPushed={setIsTicketPushed}
      />

      <h1>Home Page</h1>

      {/* Other content */}
      <HomeImage src="/images/Game_logo.png" alt="App Logo" />
      <TitleImage src="/images/Bet_on_AI_Story_Title.png" alt="Game Logo Title" />

      <Text>
        Make your choice, and the oracle will decide who is the selected warrior!
      </Text>

      <Text>So warriors, are you ready to set sail? 🗡️</Text>
      
      {/* Button for Bet! */}
      <SquareButtonWithImage
        imageSrc="/images/bet_unpushed.png"
        text="Bet!"
        onClick={() => navigate('/bet')}
      />

      {/* Button for Warrior info */}
      <SquareButtonWithImage
        imageSrc="/images/warrior.png"
        text="Joined warriors: 3233"
        onClick={() => alert('Warrior info clicked!')}
      />
    </PageWrapper>
  );
};

export default Home;
