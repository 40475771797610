import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensures background color extends the full height of the content */
  background-color: #45303f; /* Keeps the purple background color */
  color: white;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
`;

const ResultImage = styled.img`
  max-width: 100%; /* Ensures the image is responsive */
  height: auto; /* Maintains the aspect ratio */
  margin-bottom: 20px;
`;

const Result = () => {
  return (
    <PageWrapper>
      <h1>Result Page</h1>
      <ResultImage src="/images/result.png" alt="Result" />
    </PageWrapper>
  );
};

export default Result;
