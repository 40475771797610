import React, { useState } from 'react';
import styled from 'styled-components';
import detectEthereumProvider from '@metamask/detect-provider';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';  // Import the new shared Header component

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #2c0f3a;
  color: white;
  font-family: 'Press Start 2P', cursive;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 150px;
`;

const LogoImage = styled.img`
  width: 300px;
  margin-bottom: 20px;  /* Add margin for space between logo and text */
`;

const TitleImage = styled.img`
  width: 250px;  /* Adjust width as needed */
  position: relative;  /* Use relative positioning */
  top: -30px;  /* Move the TitleImage 30px upwards */
`;

const SubTitle = styled.p`
  font-family: 'Minecraft', sans-serif;
  font-size: 1rem;
  margin-bottom: 20px;
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState(null);  // State to store wallet address
  const [isPrizePushed, setIsPrizePushed] = useState(false);
  const [isLoginPushed, setIsLoginPushed] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);  // State to track if we are connecting

  const handleLogin = () => {
    console.log("Login button clicked");
    setIsLoginPushed(true);
    setTimeout(() => {
      window.location.href = '/auth/twitter';  // Redirect for Twitter OAuth
    }, 300);
  };

  const connectWallet = async () => {
    if (isConnecting) return;  // Prevent multiple clicks during connection
  
    setIsConnecting(true);  // Set to true while connecting
    const provider = await detectEthereumProvider();
  
    if (provider) {
      try {
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];
        setWalletAddress(walletAddress);  // Store the wallet address
        console.log('Wallet connected:', walletAddress);

        // Redirect to home and pass walletAddress as state
        setTimeout(() => navigate('/home', { state: { walletAddress } }), 1000);
      } catch (error) {
        console.error('User rejected the connection request:', error);
      }
    } else {
      alert('MetaMask not detected. Please install MetaMask.');
    }
  
    setIsConnecting(false);  // Reset the connection state after attempt
  };

  return (
    <PageWrapper>
      {/* Use the shared Header component */}
      <Header
        walletAddress={walletAddress}
        connectWallet={connectWallet}
        isPrizePushed={isPrizePushed}
        setIsPrizePushed={setIsPrizePushed}
        setIsTicketPushed={() => {}}
      />

      <Content>
        <LogoImage src="/images/Game_logo.png" alt="Game Logo" />
        <TitleImage src="/images/Bet_on_AI_Story_Title.png" alt="Game Logo Title" />
        <SubTitle>Test your fortune in this AI story</SubTitle>
      </Content>
    </PageWrapper>
  );
};

export default LandingPage;
